<template>
  <div class="gachaProbability">
    <main>
      <h1>제공 비율</h1>
      <section v-for="item1 in actList" :key="item1.sectionTitle">
        <h2>▶ {{ item1.sectionTitle }}</h2>
        <div v-for="item2 in item1.children" :key="item2.title">
          <div>
            <h3>【{{ item2.name_kr }}】</h3>
            <div class="table_list_wrap">
              <h3>【{{ item2.name_kr }}】</h3>
              <div class="table_list">
                <div
                  class="table_wrap"
                  v-for="table in tableList"
                  :key="table.tableKey"
                >
                  <h4>{{ table.title }}</h4>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            v-for="column in tableColumns"
                            :key="column.value"
                          >
                            <span>{{
                              column.value === "prob"
                                ? column.text
                                : table.headerName
                            }}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(row, rowIndex) in item2[table.tableKey]"
                          :key="rowIndex"
                        >
                          <td>{{ getPrizeNameKrByPid(row.pid) }}</td>
                          <td>{{ row.prob }}%</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import prob from "@/mixins/prob/prob";

export default {
  name: "gachaProbabilityNew",
  mixins: [prob],
  data() {
    return {};
  },
  created() {
    this.handleSetLanguage("kr");
  },
  methods: {
    handleSetLanguage(lang) {
      Cookies.set("language", lang, { expires: 30 });
      this.$store.dispatch("setLanguage", lang);
      this.$i18n.locale = lang;
    },
  },
};
</script>
<style lang="scss" scoped>
.gachaProbability {
  padding: 50px 20px;
  background-image: url("/static/img/common/policyBackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  main {
    width: 1000px;
    margin: 0 auto;
    color: #fff;
    h1 {
      text-align: center;
      margin-bottom: 20px;
    }
    h2 {
      margin-bottom: 20px;
    }
    h3 {
      margin-bottom: 10px;
    }
    section {
      padding: 20px 30px;
      background-color: #3d2f61;
      margin-bottom: 30px;
      .table_list_wrap {
        width: 100%;
        max-height: 500px;
        overflow-y: auto;
        margin-bottom: 30px;
        background: #fff;
        padding: 20px 30px;
        h3 {
          color: #000000;
        }
        .table_list {
          display: flex;
          justify-content: space-between;
          h4 {
            color: #000000;
            text-align: center;
            margin-bottom: 6px;
          }
          .table_wrap {
            width: calc(100% / 3);
            margin-right: 30px;
            &:last-child {
              margin-right: 0;
            }
            th,
            td {
              border: 1px solid #000;
              border-right: none;
              text-align: center;
              font-size: 15px;
              &:last-child {
                border-right: 1px solid #000;
              }
            }
            th {
              background-color: #e7e6e6;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .gachaProbability {
    main {
      width: 600px;
      section .table_list_wrap .table_list {
        flex-wrap: wrap;
        .table_wrap {
          width: 100%;
          margin-right: 0;
          margin-bottom: 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .gachaProbability {
    main {
      width: 100%;
    }
  }
}
</style>
