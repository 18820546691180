import { getActProbList } from "@/api/prob";
export default {
  data() {
    return {
      tableColumns: [
        {
          text: "",
          value: "name_kr",
        },
        { text: "입수 확률", value: "prob" },
      ],
      tableList: [
        {
          title: "캐릭터 (5%)",
          headerName: "캐릭터",
          tableKey: "roleList",
        },
        {
          title: "장식품(15%)",
          headerName: "장식품",
          tableKey: "decoList",
        },
        {
          title: "아이템(80%)",
          headerName: "아이템",
          tableKey: "propList",
        },
      ],
      prizeList: [],
      actList: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const res = await getActProbList({
        token: this.$route.query.token || undefined,
      });
      console.log("res:", res);
      if (res.data.code !== 0) return;
      this.prizeList = res.data.prize;
      const defaultActListString = "[]";
      const actList = [
        {
          sectionTitle: "한정모집",
          type: 2,
          children: [],
        },
        {
          sectionTitle: "상설모집",
          type: 1,
          children: [],
        },
      ];
      const listKeyMap = {
        roleList: "prob_role",
        decoList: "prob_deco",
        propList: "prob_prop",
      };
      res.data.act.forEach((item) => {
        let emptyListCount = 0;
        for (const key in listKeyMap) {
          if (Object.hasOwnProperty.call(listKeyMap, key)) {
            const backKey = listKeyMap[key];
            if (item[backKey] === null) {
              item[key] = [];
            } else {
              item[key] = JSON.parse(item[backKey] || defaultActListString);
            }
            item[key].sort((a, b) => a.sort - b.sort);
            if (item[key].length === 0) {
              emptyListCount++;
            }
          }
        }
        // 清除空列表
        if (emptyListCount === 3) {
          return;
        }
        if (item.type === 2) {
          actList[0].children.push(item);
        } else if (item.type === 1) {
          actList[1].children.push(item);
        }
      });
      this.actList = actList;
    },
    getPrizeNameKrByPid(pid) {
      const prize = this.prizeList.find((item) => item.id === pid);
      return prize ? prize.name_kr : "";
    },
  },
};
